import p1 from "../../../content/assets/radovi1.png"
import p2 from "../../../content/assets/radovi2.png"
import p4 from "../../../content/assets/radovi4.png"
import p5 from "../../../content/assets/radovi5.png"
import p6 from "../../../content/assets/radovi6.png"
import p7 from "../../../content/assets/radovi7.png"
import p8 from "../../../content/assets/radovi8.png"
import p9 from "../../../content/assets/radovi9.jpeg"
import p10 from "../../../content/assets/radovi10.jpeg"

const Images = [
  { photo: p1, in: 0 },
  { photo: p2, in: 1 },
  { photo: p4, in: 2 },
  { photo: p5, in: 3 },
  { photo: p6, in: 4 },
  { photo: p7, in: 5 },
  { photo: p8, in: 6 },
  { photo: p9, in: 7 },
  { photo: p10, in: 8 },
]

export default Images
